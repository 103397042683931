import { cx } from '@emotion/css';

import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import type { Authorities } from 'app';
import { Sidebar } from 'widgets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { LogoutButton } from 'features/logout';

import { UserContext, useAuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';
import { Button } from 'shared/ui';

export const Layout = (props: { authorities: Authorities }) => {
  const navigate = useNavigate();
  const { isAuth } = useAuthContext();

  const [isSmallSidebar, setIsSmallSidebar] = useState(true);

  const onClick = () => {
    setIsSmallSidebar(!isSmallSidebar);
  };

  useEffect(() => {
    if (!isAuth) {
      navigate(RoutesPath.AUTH);
    }
  }, [isAuth]);

  return (
    <UserContext.Provider value={{}}>
      <header className="flex h-[60px] items-center justify-between bg-brand-1 pr-2">
        <Button
          className="h-full w-[var(--sidebar-width)] flex-center"
          onClick={onClick}
        >
          <FontAwesomeIcon
            icon={faArrowRightLong}
            className={cx(
              'size-6 text-white transition',
              !isSmallSidebar ? 'rotate-180' : 'rotate-0',
            )}
          />
          <div className="absolute right-[2px] flex size-6 w-px items-center justify-between bg-white/20" />
        </Button>
        <LogoutButton />
      </header>
      <div
        className={cx(
          'grid',
          'transition-[grid-template-columns] duration-225 ease-in-out',
          {
            'grid-cols-[225px_auto]': !isSmallSidebar,
            'grid-cols-[var(--sidebar-width)_auto]': isSmallSidebar,
          },
        )}
        style={{ height: 'calc(100vh - 60px)' }}
      >
        <Sidebar
          isSmallSidebar={isSmallSidebar}
          authorities={props.authorities}
        />
        <div
          className="size-full bg-brand-4 p-2"
          style={{ height: 'calc(100vh - 60px)' }}
        >
          <Outlet />
        </div>
      </div>
    </UserContext.Provider>
  );
};
