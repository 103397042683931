import { DialogPanel, Dialog as HeadlessuiDialog } from '@headlessui/react';
import type { HTMLAttributes } from 'react';

export type DialogSimpleProps = {
  isOpen: boolean;
  onClose: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const DialogSimple = ({
  isOpen,
  onClose,
  children,
}: DialogSimpleProps) => {
  return (
    <HeadlessuiDialog
      open={isOpen}
      as="div"
      className="relative z-10 focus:outline-none"
      onClose={onClose}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#000000AA]">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="data-[closed]:transform-[scale(95%)] min-h-96 w-fit  max-w-screen-xl  overflow-x-hidden rounded-xl border border-brand-2/40 bg-brand-1/50 p-6 backdrop-blur-2xl duration-100 ease-out data-[closed]:opacity-0"
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </HeadlessuiDialog>
  );
};
