import { jwtDecode } from 'jwt-decode';

import type { AUTHORITIES } from 'entities/user';

export const getAuthoritiesFromToken = async (
  token: string,
): Promise<string[]> => {
  try {
    const decoded: any = await jwtDecode(token);
    const authorities = decoded.authorities || [];
    return authorities.map(
      (auth: { authority: keyof typeof AUTHORITIES }) => auth.authority,
    );
  } catch (error) {
    console.log(error);
    return [];
  }
};
