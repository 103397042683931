import { HttpStatusCode } from 'axios';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableQueryParams } from 'shared/typings';

import type { User } from './model';

export class UserApi {
  static readonly KEY = 'user';

  static async getUsers(params: TableQueryParams): Promise<{
    data: Partial<User>;
    success: boolean;
    error: Error | null;
    errorMessage: Error['message'] | null;
    code: HttpStatusCode | null;
  }> {
    const { data } = await axiosInstance.get(`/users`, {
      params,
    });
    return data;
  }

  static async create(params: User): Promise<{
    errorMessage: Error['message'] | null;
  }> {
    return await axiosInstance.post(`/users/create`, params);
  }

  static async update(params: User): Promise<void> {
    return await axiosInstance.post(`/users/update`, params);
  }
}
