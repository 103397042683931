import { Product } from 'entities/product/model';

import { axiosInstance } from 'shared/api/axios.defaults';
import { TableQueryParams, TableResponse } from 'shared/typings';

export class ProductApi {
  static readonly KEY = 'product';

  static async create(params: Omit<Product, 'id'>): Promise<{
    errorMessage: string;
  }> {
    const response = await axiosInstance.post(
      `/credit-products/create`,
      params,
    );
    return response.data;
  }

  static async update(params: Partial<Product>): Promise<void> {
    const response = await axiosInstance.post(
      `/credit-products/update`,
      params,
    );
    return response.data;
  }

  static async getProducts(
    params: TableQueryParams,
  ): Promise<TableResponse<Product>> {
    const { data } = await axiosInstance.get(`/credit-products`, {
      params,
    });
    return data;
  }
}
